@import '../css/shared';

.cart-page {

  .cart-summary {

    h6 {font-weight: 700;}

  }
  
  .Accordion {
    .row > [class^='col-'] {
      .accordion-item {
        border-bottom: 0;
      }
      &:last-child {
        .accordion-item {
          border-bottom: 1px solid var(--bs-light);
        }
      }  
    }
  
    .accordion-item {
      border-left: 0;
      border-right: 0;
    }
    
    .accordion-button:not(.collapsed){
      box-shadow: none !important;
    }
  
    @include media-breakpoint-down(lg) {}
    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-down(lg) {}
  }
  
  .cart-to-free-shipping {
    background: $secondary;
    font-weight: 700;
  }

  .generic-coupon-form {
    input:focus + .btn {border-color: $primary !important}
    input.is-invalid + .btn {border-color: $red !important}
  }

  #form-cartGenericCoupon {
    input {
      border-color: var(--bs-dark) !important;
    }

    button {
      z-index: 100;
      opacity: 1 !important;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 14px);
        background: var(--bs-dark);
      }
    }
  }

}