.account-loyalty-page {
  .item-rule-name {
    @media (max-width: 575px) {
      min-width: 3.45rem;
    }
  }

  .point-bar {
    margin-top: 1.25rem;
    height: 1.25rem;
    border-radius: 3.75rem;
    position: relative;
    width: 100%;
    .bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.25rem;
      border-radius: 3.75rem;
      .point-wrapper {
        position: relative;
        width: 100%;
        height: 1.25rem;
        border-radius: 3.75rem;

        .point {
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          background-color: #fff;
          position: absolute;
          right: 0.25rem;
          top: 0.25rem;
          z-index: 2;
        }
        .point-name {
          position: absolute;
          font-weight: bold;
          transform: translate(50%, 50%);
          right: 0.5rem;
          bottom: -1.25rem;
        }
      }
    }
  }
}
