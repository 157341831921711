@import '../css/shared';

.search-page {

  .order-dropdown {

    button:after {display: none;}

  }

  .search-filters {
    padding: 5px;
    .search-result {background: $gray-100;}

    .text-primary {color: $blue !important;}
    ul {
      background-color: $white;
      padding-top: spacer(1);
      padding-bottom: spacer(1);
      > li {
        padding-left: spacer(2);
      }
    }    

    .other-search-filter {
      margin-left: spacer(2);
    }
    .accordion {

      .accordion-header  {

        &:not(.main-category) {
          color: $blue;
        }
        .btn {color:  $primary !important;}

      }
      .accordion-item {
//        padding-left: spacer(2);
      }      
    }

  }

  @include media-breakpoint-up(lg) {
    
    .show-more {

      .content {
        max-height: 300px;
        transition: max-height 0.5s ease-in-out;
        
        &.show-more-active {
          max-height: 500px;
          overflow-y: scroll;
        }
      
      }

    }

  }

}