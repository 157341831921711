@import 'css/shared';

$fade-duraion: 200ms;

.MobileHeader {
  background-color: $white;
  transition: background-color $fade-duraion;
  &.transparent {
    background-color: transparent;
    border-bottom-color: transparent !important;
  }
}

.MobileMenu {
  max-width: calc(100% - 50px);
  .Mmenu {
    .slide {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transform: translateX(100%);
      transition: transform 200ms;
      &.active {
        display: block;
        transform: translateX(0%);
      }
      &.child-active {
        display: block;
        transform: translateX(-10%);
      }
    }
  }
}
