@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icons/fonts/icomoon.eot?11346j');
  src: url('../fonts/icons/fonts/icomoon.eot?11346j#iefix') format('embedded-opentype'),
    url('../fonts/icons/fonts/icomoon.ttf?11346j') format('truetype'),
    url('../fonts/icons/fonts/icomoon.woff?11346j') format('woff'),
    url('../fonts/icons/fonts/icomoon.svg?11346j#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.jost {
  font-family: 'Jost';
  font-weight: 400;
}

.display-1,
.display-2 {
  @extend .jost;
}

.fw-light {
  font-weight: 300;
}

.fw-semi-bold {
  font-weight: 500 !important;
}

.extra-small {
  font-size: $font-size-base * 0.75;
}
