@import '../css/shared';

.product-category-slider {
  .swiper {
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding: 0 1rem;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      color: $gray-400;
      font-size: 35px;
    }
  }

  @include media-breakpoint-down(xl) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;
    }
  }
}
