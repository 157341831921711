@import 'functions';
@import 'bootstrap';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';

// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

body {
  overflow-y: scroll;
}

.container-fluid-w-custom {
  @include media-breakpoint-up(xxl) {
    max-width: 1920px;
  }
}

.btn {
  text-transform: uppercase;
  &.no-uppercase {
    text-transform: none;
  }
}

.btn-secondary {
  color: $white;
  &:hover,
  &:focus {
    color: $white;
  }
}
