@import 'css/shared';

.best-price {

    .btn {text-transform: none;}
    b {font-weight: 500;}

}

.best-price-modal {

    .price-container {

        .price {
            font-size: $h5-font-size;
            font-weight: 500;
        }

    }

    b {font-weight: 500;}

    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-down(lg) {}

}

.ProductPage .best-price button {
    vertical-align: baseline;
}

.ProductTile .best-price button {
    font-size: 0.75rem;
    line-height: 0.75rem;
    vertical-align: baseline;
}