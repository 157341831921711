@import '../css/shared';

.NewsletterForm {
  .custom-icon-btn-group {
    .form-control {
      font-size: 0.875rem;
      line-height: 1.7143;
      border-width: 0;
      border-radius: 1.5625rem !important;
      &.is-invalid:focus {
        box-shadow: none;
      }
      &.is-invalid {
        padding-right: 4.125rem;
        background-position: right 2.25rem center;
      }
    }

    .btn {
      margin-top: 0.4375rem;
      border-radius: 50% !important;
      width: 1.625rem;
      height: 1.625rem;
      line-height: 1.625rem;
      z-index: 4;
      position: absolute;
      right: 0.3125rem;
      .Icon {
        width: 0.8em;
        height: 0.8em;
        color: $white;
      }
    }
  }
}
