@import 'css/shared';

.brand-content-section {
  .brand-main-wrapper {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
  }
  .brand-main-content {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 0;
  }
  .brand-list {
    position: relative;
    top: -1.5rem;

    .rounded-3 {
      border-radius: 1.5rem !important;
    }
  }
  @include media-breakpoint-up(md) {
    .brand-list {
      position: relative;
      top: -2.5rem;
    }
  }
}
