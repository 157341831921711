@import '~bootstrap/scss/functions';
@import 'variables';

// RIDEFINISCO ALCUNE COSE SENZA MODIFICARLE, GIUSTO PER AVERLE DIPONIBILI >>>>>

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$display-font-sizes: (
  2: 2.5rem,
);

// BOOTSTRAP OVERRIDES BEFORE IMPORT >>>>>

$grid-gutter-width: 2rem;

$enable-negative-margins: true;

$custom-container-max-widths: (
  xxl: 1434px,
);

$font-family-sans-serif: 'Quicksand', sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 3.75;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 2.25;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.125;

$display-font-sizes: (
  1: 3.75rem,
  2: 2.5rem,
);

$promo: #ff3c4c;
$black: #000;
$white: #fff;
$gray-100: #f4f6fa; // light
$gray-200: #e5e5e5;
$gray-300: #696969;
$gray-400: #42464e;
$blue: #001689;
$blue-light: #0047bb;
$turquoise-light: #a3ebd5;
$turquoise: #47d7ac;

$custom-colors: (
  'promo': $promo,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'blue': $blue,
  'blue-light': $blue-light,
  'turquoise-light': $turquoise-light,
  'turquoise': $turquoise,
);

$body-bg: $white;
$body-color: $black;

$primary: $blue-light;
$secondary: $turquoise;

$input-border-radius: $font-size-base * 1.5625;

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0;

//$enable-rounded: false;

// Button
$input-btn-padding-y: spacer(2);
$btn-border-radius: 50px;
$btn-padding-x: spacer(4);
$btn-font-weight: 600;

$input-border-color: $gray-200;
$border-color: $gray-200;

@import '../../node_modules/bootstrap/scss/variables';
// @import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');

.zindex-dropdown {
  z-index: $zindex-dropdown;
}

.zindex-sticky {
  z-index: $zindex-sticky;
}

.zindex-fixed {
  z-index: $zindex-fixed;
}

.zindex-modal-backdrop {
  z-index: $zindex-modal-backdrop;
}

.zindex-modal {
  z-index: $zindex-modal;
}

.zindex-popover {
  z-index: $zindex-popover;
}

.zindex-tooltip {
  z-index: $zindex-tooltip;
}

$nav-link-font-weight: 700;
$nav-link-color: $gray-300;
$nav-link-hover-color: $primary;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: $white;
