@import '../css/shared';

.slider-button {
  svg {
    fill: #000000;
  }

  &.swiper-button-prev {
    left: -1rem;
  }
  &.swiper-button-next {
    right: -1rem;
  }
  &.swiper-button-prev,
  &.swiper-button-next {
    margin: auto;
    transition: opacity 0.3s;

    &.swiper-button-large {
      width: 55px;
      height: 55px;
      top: 0;
      bottom: 0;
    }

    &:after {
      display: none;
    }
  }

  // @include media-breakpoint-down(lg) {
  //   &.swiper-button-prev,
  //   &.swiper-button-next {
  //     display: none;
  //   }
  // }
}
