@import '../css/shared';

.SliderVertical {
  height: 30px;
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}