@import 'css/shared';

$fade-duraion: 200ms;

.header-checkout-desktop {
  .logo {
    width: auto;
    height: 50px;
  }
}

.header-first-row {
  .simple-search-form input {
    border-radius: 0px;
  }

  .menu {
    .menu-item {
      border-bottom-color: transparent;
      position: relative;

      &.active {
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          background: $primary;
          position: absolute;
          bottom: -8px;
          left: 0;
        }
      }
    }
  }

  .header-first-row-wrapper {
    position: relative;
    z-index: 3;
  }

  .sub-menu-dropdown {
    position: absolute;
    z-index: 2;
    background-color: $white;
    opacity: 0;
    transform: translateY(-15px);
    transition: opacity 0.8s ease, transform 1s ease;

    &.mounted {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
