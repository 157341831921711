@import '../css/shared';

.banner-slider {
  max-width: 1920px;
  margin: 0 auto;

  .message {
    padding-left: 12px;
    padding-right: 12px;
  }

  .slide {
    width: 100% !important;
    padding: 0 !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      color: $white;
    }
  }

  @include media-breakpoint-up(md) {
    .title {
      line-height: 1;
    }
    .message {
      position: absolute;
      z-index: 100;
      bottom: 0;
      width: 100%;
    }

    .swiper-pagination {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    .swiper-button-prev,
    .swiper-button-next {
      top: 20%;
    }
    .message {
      text-align: center !important;
    }
    .title {
      font-size: 1.625rem;
      color: $blue !important;
    }
    .description {
      font-size: 1rem;
      color: $dark !important;
    }

    .swiper-pagination {
      top: calc(100% - 20px) !important;
      bottom: auto !important;
    }
  }
}
