@import 'css/shared';

.product-tile {
  z-index: 100;
  .swiper {
    width: 100%;

    label,
    .d-inline-block {
      display: block !important;
      margin: 0 !important;
    }

    .CustomRadio {
      margin: 0 auto !important;
    }

    .d-inline-block {
      padding: 2px 0;
    }
  }

  .card-header {
    position: relative;

    .product-action {
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
    }

    .promo,
    .new-product-sticker {
      position: absolute;
      left: 0;
      padding: 0.125rem 0.625rem;
      margin-bottom: 0;
    }
    
    .promo {
      font-weight: bold;
      bottom: 0.625rem;
      color: $white;
      background-color: $pink;
    }

    .new-product-sticker {
      top: 0.625rem;
      left: 0.625rem;
      color: $black;
      background: $light;
      border: 1px solid $black
    }
  }

  @include media-breakpoint-up(md) {
    .card-title {
      min-height: 40px;
      margin-bottom: 0;
    }
  }

  .product-action {
    .Icon {
      font-size: 1.5625rem;
    }
  }

  .price {
    white-space: nowrap;

    .text-offer {
      margin-top: 0;

      @include media-breakpoint-up(md) {
        margin-top: -0.375rem;
      }
    }
  }

  .colors {
    opacity: 1;
    transition: opacity 0.2s linear;
  }

  .card-footer {
    background-color: $white;
    margin-top: -1.7rem;
  }

  &.hover {
    box-shadow: 0px 3px 8px rgba($color: #000000, $alpha: 0.4);

    .colors {
      opacity: 0;
    }
  }
}
