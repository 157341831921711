@import 'css/shared';

.cart-offcanvas {

  .footer-message {
    padding: map-get($spacers, 3) 0 0;
    text-align: center;
  }
 
}
